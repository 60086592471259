import React from "react";
import styles from "./Home.module.css";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import toast, { Toaster } from "react-hot-toast";
import Telegram from "@material-ui/icons/Telegram";
import ModalPage from "./Modal";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import logoHn from "../../assets/img/logoHn.png";
import logoEn from "../../assets/img/logoEn.png";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-adornedEnd": {
      background: "#1fd5bf",
      borderRadius: "8px",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "white !important",
      borderRadius: "8px 0px 0px 8px",
      height: 30,
      paddingLeft: "10px !important",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const notify = (message) => toast(message);

  const [email, setEmail] = React.useState("");
  const [logo, setLogo] = React.useState(logoHn);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const interval = setTimeout(() => {
      if (logo === logoEn) {
        setLogo(logoHn);
      }
      if (logo === logoHn) {
        setLogo(logoEn);
      }
    }, 4000);

    return () => clearInterval(interval);
  });

  const onSubmit = () => {
    let isEmail =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; /* eslint-disable-line */
    if (!isEmail.test(email)) {
      setError("Enter Valid Email");
      notify("Please Enter Valid Email!");
    } else {
      axios({
        method: "post",
        url: "/api/comingSoon/addEmail",
        data: {
          email,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setError(false);
          setEmail("");
          notify("Thanks for your interest. We will get back to you…");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              background: "#1fd5bf",
              color: "#fff",
              fontFamily: "Montserrat",
              fontSize: "13px",
              fontWeight: 600,
            },
          }}
        />
        <ModalPage open={open} onClose={() => setOpen(false)} />

        <div className={styles.contentData}>
          <div className={styles.flex}>
            <div>
              <img
                src={require("../../assets/img/logoOnly.png")}
                loading="lazy"
                alt="...loading"
                className={styles.logoOnly}
              />
            </div>
            <div>
              <img
                src={logo}
                alt="loading...."
                loading="lazy"
                className={styles.logoLang}
              />
            </div>
            <div></div>
          </div>
          {/* <img src={logo} loading="lazy" alt="...Loading" /> */}

          <h1>We are launching soon!</h1>
          <span>stay tuned</span>
          <div className={styles.email}>
            <FormControl className={styles.formControl}>
              <TextField
                className={classes.textField}
                placeholder="email"
                id="standard-name"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={error}
                helperText={"Enter Valid Email"}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <div className={styles.inputEnd}>
                      <button onClick={onSubmit}>
                        <Telegram />
                      </button>
                    </div>
                  ),
                }}
              />
            </FormControl>
          </div>
          <div className={styles.footer}>
            <button
              style={{ background: `${!open ? "white" : "#1fd5bf"}` }}
              onClick={() => setOpen(true)}
            >
              contact
            </button>

            <div className={styles.copyRights}>
              <p>@swachh.copyrights</p>
              <p>M/S SAUVE AGRO TECH PRIVATE LIMITED</p>
              <p>CIN U25111KA2015PTC081922</p>
              <p>GST 29AAWCS0753K1ZL</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

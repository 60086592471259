import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styles from "./styles/app.module.css";
import Home from "./components/Home/Home";
import axios from "axios";
import { backendUrl } from "./config/config";

axios.interceptors.request.use(async (config) => {
  config.url = config.externalUrl ? config.url : backendUrl + config.url;

  if (localStorage.token && !config.externalUrl) {
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem("token"),
    };
  }
  return config;
});

class App extends React.Component {
  render() {
    return (
      <div className={styles.appContainer}>
        <Router>
          
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
         
        </Router>
      </div>
    );
  }
}

export default App;

import React from "react";
import styles from "./Modal.module.css";
import Modal from "@material-ui/core/Modal";
import { FormControl, FormLabel, TextField } from "@material-ui/core";
import axios from "axios";
import toast from "react-hot-toast";

const ModalPage = (props) => {
  const notify = (message) => toast(message);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    comment: "",
  });
  const [error, setError] = React.useState(false);
  const onSubmit = () => {
    let isEmail =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; /* eslint-disable-line */
    if (!isEmail.test(formData.email)) {
      setError("Enter Valid Email");
      notify("Please Enter Valid Email!");
    } else {
      axios({
        method: "post",
        url: "/api/comingSoon/addContact",
        data: {
          ...formData,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setFormData({ ...formData, name: "", email: "", comment: "" });
          props.onClose();
          notify("Message Sent");
        })
        .catch((err) => {
          props.onClose();
          notify("Please Try again in sometime");
          console.log(err);
        });
    }
  };

  return (
    <Modal open={props.open ? true : false} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>
              Contact Us <br />
              <span>
                Send email or call or message below "karan@swachh.io" & +919999056761
              </span>
            </h2>
            <span className={styles.closeButton}>
              <svg
                onClick={() => props.onClose()}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13M1 1L13 13"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div className={styles.body}>
            <FormControl className={styles.fullWidth} fullWidth>
              <FormLabel className={styles.label}>Name</FormLabel>

              <TextField
                size="small"
                // className={classes.textfield}
                variant="outlined"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </FormControl>

            <FormControl className={styles.fullWidth} fullWidth>
              <FormLabel className={styles.label}>Email</FormLabel>

              <TextField
                size="small"
                // className={classes.textfield}
                variant="outlined"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                error={error}
                helperText={error}
              />
            </FormControl>

            <FormControl className={styles.fullWidth} fullWidth>
              <FormLabel className={styles.label}>Write your Comment</FormLabel>

              <TextField
                variant="outlined"
                multiline
                rows={4}
                value={formData.comment}
                onChange={(e) =>
                  setFormData({ ...formData, comment: e.target.value })
                }
                placeholder="Type Here"
              />
            </FormControl>
          </div>
          <div className={styles.button}>
            <button onClick={onSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPage;
